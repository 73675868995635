.tableOfContents {
  display: flex;
  // width: 100%;
  // height: calc(100vh - 96px);
  align-items: center;
  margin: 56px 6rem;
  padding-bottom: 2rem;
  background: white;
  @media (max-width: 884px) {
    margin: 56px 0;
  }
  &_wrapper {
    display: flex;
    align-items: center;

    flex-direction: column;

    padding-left: 0.5rem;
    padding-right: 0.5rem;
    @media (min-width: 1081px) {
      padding-left: 8vw;
      padding-right: 8vw;
    }
    margin: auto;
    #toc-title {
      margin-top: 2rem;
    }
    img {
      object-fit: cover;
      width: 95%;
      // margin-top: 4rem;
      @media (min-width: 721px) {
        width: 60%;
      }
    }
    .toc {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      // flex-direction: column;
      position: relative;
      justify-content: space-between;
      padding-left: 2vw;
      padding-right: 2vw;
      margin-top: 1rem;
      font-family: "Baskerville URW", sans-serif;
      color: black;
      @media (min-width: 641px) {
        flex-direction: row;
      }
      h3 {
        font-family: Franklin Gothic Demi Cond, sans-serif;
        color: black;
        text-transform: uppercase;
        position: relative;
        width: fit-content;
        margin-top: 2rem;
        margin-left: 1rem;
        &:after {
          content: "";
          background: var(--color-PE-blue);
          width: 100%;
          height: 4px;
          position: absolute;
          display: block;
          top: -18%;
        }
      }
      .flex-break {
        flex: 1 0 100% !important;
        width: 0 !important;
      }
      .left,
      .right {
        width: 100%;
        @media (min-width: 721px) {
          width: 50%;
        }
      }
      &-item {
        position: relative;
        display: block;
        width: 100%;
        max-height: 300px;
        overflow: hidden;
        padding: 0.5rem;
        border-radius: 10px;
        // border: 2px solid var(--color-PE-blue);
        // margin-bottom: 2rem;
        transition: all 0.4s ease-in-out;
        color: black;
        @media (min-width: 541px) {
          padding: 1rem;
        }
        &__bg {
          width: 100%;
          img {
            position: relative;
            display: block;
            margin-top: 0;
            object-fit: cover;
            width: 100%;
          }
          &:after {
            content: "";
            background: rgba(0, 0, 0, 0.2);
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
          }
        }
        &__text {
          // position: absolute;
          margin: 0;
          left: 1rem;
          top: 65%;
          font-size: 22px;
          font-weight: 700;
          color: var(--color-PE-blue);
          z-index: 3;
          &:after {
            content: "";
            // background: white;
            width: 240px;
            height: 6px;
            position: absolute;
            display: block;
            top: -15%;
          }
        }
        &__subtext {
          margin: 0;
          // position: absolute;
          left: 1rem;
          top: 87%;
          font-size: 18px;
          // font-weight: 700;
          // color: white;
          line-height: 1.15;
          z-index: 4;
          font-style: italic;
          @media (min-width: 541px) {
            font-size: 22px;
          }
        }
        &:hover {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          transform: scale(1.1);
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
