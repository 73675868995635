.quick-on-the-draw {
  .hero {
    width: 100%;
    display: block;
    height: var(--hero-height);
    position: relative;
    &-images {
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
        object-position: bottom;
      }
    }
    &-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      h1 {
        font-family: "Baskerville Poster PT", sans-serif;
        color: black;
        font-style: italic;
        display: block;
        font-size: 14vw;
        font-weight: 400;
        position: absolute;
        top: 28%;
        left: 53%;
        transform: translate(-50%, -50%);
        @media (max-width: 550px) {
          font-size: 6rem;
        }
      }
      h2 {
        color: var(--color-PE-orange);
        display: block;
        font-family: Franklin Gothic Demi Cond;
        font-size: 8vw;
        position: absolute;
        top: 16%;
        left: 51%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        white-space: nowrap;
        letter-spacing: 2px;
        @media (max-width: 550px) {
          font-size: 5rem;
        }
        span {
          color: var(--color-PE-gray);
          font-weight: 400;
          font-size: 4vw;
          font-family: sans-serif;
          text-transform: lowercase;
          @media (max-width: 550px) {
            font-size: 1.5rem;
          }
        }
      }
      .by-line {
        color: black;
        font-style: normal;
        font-size: 16px;
        // margin-top: 1rem;
        position: absolute;
        top: 39%;
        left: 63%;
        transform: translate(-50%, -50%);
        @media (max-width: 550px) {
          left: 50%;
        }
      }
    }
  }
  h6 {
    color: var(--color-gray);
    font-family: "Baskerville URW", sans-serif;
    font-style: italic;
    display: block;
    // width: 60%;
    // max-width: 500px;
    // margin-left: 30%;
    font-size: 2rem;
  }
  h5 {
    font-size: 1rem;
  }
  #food-truck {
    width: 350px;
    max-width: 100%;
    margin: auto;
    display: block;
  }
  .personal-pursuits-img__wrapper {
    width: 100vw;
    display: block;
    h5 {
      padding: 0 4%;
    }
  }

  #personal-pursuits-img {
    width: 100vw;
    max-width: 100vw;
  }

  .caption {
    color: gray;
    max-width: 100%;
    width: 100%;
    margin: auto;
    padding: 0.5rem;
    text-align: center;
  }
  .slick-list {
    border: 1px black solid;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    // padding: 1rem;
  }
  .slick-slide {
    border: none;
  }
  .slick-slider {
    margin: 2rem auto;
    .images-single {
      overflow: hidden;
      padding: 1rem;
    }
    img {
      height: 400px;
      max-width: 100%;
      object-fit: contain;
      box-shadow: none;
      margin: 0;
    }
    h5 {
      margin-top: 1rem;
    }
  }
  .personal-pursuits-images {
    padding-bottom: 4rem;
  }
  .static {
    img {
      // margin-bottom: 2rem;
    }
  }
  .personal-pursuits {
    font-family: Franklin Gothic Book, sans-serif;
    color: var(--color-PE-orange);
    margin-top: 4rem;
    text-align: center;
    font-size: 7vw;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
    @media (max-width: 500px) {
      font-size: 3rem;
      white-space: break-spaces;
    }
    span {
      font-family: "Baskerville Poster PT", sans-serif;
      color: black;
      font-size: 7vw;
      font-weight: 400;
      font-style: italic;
      text-transform: capitalize;
      @media (max-width: 500px) {
        font-size: 3rem;
      }
    }
  }
  .image-cluster {
    width: 85%;
    margin: auto;
  }
  .slick-prev:before,
  .slick-next:before {
    color: var(--color-PE-blue);
    font-size: 30px;
  }
  .slick-prev {
    left: -35px;
  }
}
