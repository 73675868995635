.going-car-free {
  .hero {
    background: #c0bdd1;
    width: 100%;
    height: 50vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 620px) {
      height: fit-content;
    }
    &-text,
    &-right {
      width: 50%;
      height: 100%;
      display: block;
      position: relative;
      @media (max-width: 620px) {
        width: 100%;
        height: fit-content;
      }
    }
    &-text {
      h4 {
        font-family: "Baskerville Poster PT", sans-serif;
        font-style: italic;
        font-weight: 700;
        max-width: 530px;
        font-size: 1.75rem;
        margin: auto 1rem;
        @media (max-width: 620px) {
          font-size: 100%;
          position: relative;
        }
        @media (max-width: 900px) {
          font-size: 100%;
        }
      }
      h6 {
        max-width: 530px;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        // width: 60%;
        height: 100%;
      }
    }
    h1 {
      font-family: "Baskerville Poster PT", sans-serif;
      color: black;
      font-style: italic;
      width: 100%;
      text-align: center;
      display: block;
      font-size: 12vw;
      font-weight: 400;
      line-height: 1;
      position: relative;
      z-index: 2;
      // position: absolute;
      // top: 28%;
      // left: 53%;
      // transform: translate(50%, 0%);
      .park {
        color: white;
        display: block;
        font-family: Franklin Gothic Demi Cond;
        font-style: normal;
        transform: translate(-20%, 37%);
        z-index: 1;
        position: relative;
      }
      .ride {
        position: relative;
        z-index: 2;
      }
    }
    h2 {
      font-size: 8vw;
      position: absolute;
      top: 16%;
      left: 51%;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      white-space: nowrap;
      letter-spacing: 2px;
      span {
        color: var(--color-PE-gray);
        font-weight: 400;
        font-size: 4vw;
        font-family: sans-serif;
        text-transform: lowercase;
      }
    }
  }
  h6 {
    max-width: 96%;
  }
  .static {
    .two-img {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      max-width: 750px;
      margin: auto;
      justify-content: space-between;

      .single-img:nth-of-type(1) {
        width: 46%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
      .single-img:nth-of-type(2) {
        width: 48.75%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
      #left {
        width: 48%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
      #right {
        width: 47.5%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
    .images-single {
      margin: 1rem auto;
    }
    h6 {
      max-width: 750px;
      margin: 1rem auto;
    }
  }
}
