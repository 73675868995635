.the-third-act {
  .hero {
    width: 100%;
    height: var(--hero-height);
    position: relative;
    display: block;
    &-image {
      width: 100%;
      height: 100%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &-text {
      position: absolute;
      top: 4%;
      left: 43%;
      transform: translate(-50%, 0%);
      width: auto;
      @media (max-width: 1240px) {
        left: 56%;
        top: 8%;
      }
      h1 {
        font-family: Franklin Gothic Book, sans-serif;
        color: var(--color-PE-blue);
        // left: 32%;
        // top: 31%;
        position: relative;
        font-size: 5.5rem;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        width: 100%;
        line-height: 1;
        white-space: nowrap;
        @media (max-width: 1240px) {
          font-size: 3rem;
        }
      }
      h2 {
        font-family: "Baskerville Poster PT", sans-serif;
        color: black;
        // left: 32%;
        // top: 28%;
        position: relative;
        font-size: 3.5rem;
        font-weight: 400;
        font-style: italic;
        text-transform: capitalize;
        margin: 0;
        transform: translateX(-10%);
        line-height: 0.7;
        @media (max-width: 1240px) {
          font-size: 2rem;
          white-space: nowrap;
        }
      }
    }
  }
  .by-line {
    color: var(--color-gray);
    font-style: normal;
    font-size: 1rem;
    margin-top: 1rem;
  }
  h5 {
    font-size: 1rem;
    padding: 0 4%;
    text-align: center;
    margin-bottom: 2rem;
  }
  h6 {
    font-family: "Baskerville URW", "Sans-serif";
    font-size: 1.75rem;
    line-height: 1.15;
    font-style: italic;
    color: var(--color-PE-gray);
    max-width: 750px;
  }
  .static {
    .caption {
      color: var(--color-gray);
    }
    .quote {
      color: var(--color-PE-blue);
      &::before {
        opacity: 0.2;
        color: var(--color-PE-blue);
      }
      h5 {
        margin-bottom: 0;
      }
    }
  }
  .priya {
    background: url("../img/the-third-act/RochesterHills_Market_Place.jpg");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
  .active-generation {
    width: 100%;
    padding: 4%;
    margin: 0;
    display: flex;
    flex-direction: column;
    background: #d3d8ed;
    // color: var(--color-PE-blue);

    .ag-text {
      font-family: "Baskerville Poster PT", sans-serif;
      color: black;

      font-size: 2.5rem;
      font-weight: 400;
      font-style: italic;
      text-transform: none;
      margin-right: 1rem;
    }
    h4 {
      font-family: Franklin Gothic Demi Cond, sans-serif;
      color: var(--color-PE-blue);
      // left: 6%;
      // top: 7%;
      font-size: 2.5rem;
      font-weight: 700;
      text-transform: uppercase;
      display: block;
      .ag {
        margin-left: 6rem;
        display: block;
        line-height: 1;
        @media (max-width: 1240px) {
          margin-left: 2rem;
        }
      }
    }
    .images-single {
      p {
        margin-top: 0;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}
