.take-five {
  .section {
    margin: 2rem 4vw;
  }
  .hero {
    width: 100%;
    display: block;
    margin: 0;
    &-text {
      position: relative;
    }
    h1 {
      font-family: "Baskerville Poster PT", san-serif;
      color: black;
      font-style: italic;
      display: block;
      padding-top: 5vw;
      font-size: 12vw;
      position: relative;
      text-align: center;
      .take {
        font-family: sans-serif;
        text-transform: uppercase;
        color: var(--color-PE-green);
        font-style: normal;
        font-size: 6rem;
        position: absolute;
        font-weight: 700;
        transform: translate(-76%, -20%);
        z-index: 1;
        @media (max-width: 722px) {
          left: 5%;
          transform: translate(0, -20%);
        }
      }
      .five {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, 0);
        font-size: 12rem;
        z-index: 2;
        position: relative;
      }
    }
    h6 {
      color: var(--color-gray);
      font-family: "Baskerville URW", sans-serif;
      font-style: italic;
      display: block;
      width: 60%;
      max-width: 500px;
      margin-left: 45%;
      font-size: 2rem;
      margin-top: -4rem;
      @media (max-width: 922px) {
        margin-left: 0;
        margin: auto;
      }
    }
    .by-line {
      margin-left: 45%;
      max-width: 500px;
      @media (max-width: 922px) {
        margin-left: 0;
        margin: auto;
        width: 60%;
      }
    }
  }
  .person {
    margin: 2rem auto;
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    scroll-margin-top: 4rem;

    h6 {
      margin-top: 0;
      font-size: 14px;
    }

    h2 {
      width: 100%;
      max-width: 1070px;
      color: black;
      font-style: italic;
      font-family: "Baskerville URW", sans-serif;
      font-weight: 400;
      margin: 1rem 1rem;
      span {
        color: var(--color-PE-green);
        text-transform: uppercase;
        font-style: normal;
        font-weight: 700;
        font-family: sans-serif;
      }
    }
    .qa {
      display: flex;
      flex-direction: column;
      margin: 0 1rem;
      padding-bottom: 2rem;
      .question {
        // width: 15%;
        font-size: 16px;
        font-family: Franklin Gothic Demi Cond, sans-serif;
        letter-spacing: 0.5px;
        line-height: 1.15;
        font-weight: 400;
        margin: 0;
        // max-width: 300px;
        // padding: 0 1rem;
      }
      .answer {
        display: block;
        // width: 85%;
        ul {
          max-width: 700px;
          margin: auto;
        }
      }
    }
    &-text {
      //   width: 20%;
      max-width: 400px;
      width: auto;
      padding: 0 1rem;
      img {
        max-width: 300px;
        // margin: auto;
        display: block;
      }
      p {
        color: var(--color-PE-green);
        font-family: "Baskerville URW", sans-serif;
      }
    }
    .questions {
      //   width: 80%;
      max-width: 700px;
    }
  }
  .images-single {
    margin: 0 1rem;
    h6 {
      margin-top: 0;
      font-size: 14px;
    }
  }
}
