.transformational-designs {
  .hero {
    width: 100%;
    height: var(--hero-height);
    position: relative;
    &-images {
      width: 100%;
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
      }
      filter: brightness(85%);
    }
    &-text {
      position: absolute;
      top: 4%;
      left: 30%;
      width: auto;
      @media (max-width: 940px) {
        left: 15%;
        top: 9rem;
      }
      @media (max-width: 475px) {
        left: 6%;
      }
    }
    h1 {
      font-family: Franklin Gothic Demi Cond, sans-serif;
      color: #7f2268;
      line-height: 1;
      font-size: 9rem;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
      margin-top: -3.5vw;
      position: relative;
      z-index: 1;
      @media (max-width: 940px) {
        font-size: 17vw;
        margin-top: -8.5%;
      }
      @media (max-width: 475px) {
        font-size: 19vw;
        // margin-top: -8.5%;
      }
    }
    h2 {
      font-family: "Baskerville Poster PT", sans-serif;
      color: white;
      line-height: 1;
      font-size: 6rem;
      font-weight: 400;
      font-style: italic;
      margin: 0;
      position: relative;
      z-index: 2;
      @media (max-width: 940px) {
        font-size: 11vw;
      }
      @media (max-width: 475px) {
        font-size: 12vw;
        // margin-top: -8.5%;
      }
    }
    .tagline {
      position: absolute;
      left: 32%;
      top: 20%;
      transform: scaleY(1.6);
      letter-spacing: 1px;
      font-size: 1.8vw;
      font-weight: 800;
      font-family: Franklin Gothic Demi Cond, sans-serif;
      color: var(--color-PE-blue);
      text-transform: uppercase;
    }
    .by-line {
      position: absolute;
      width: fit-content;
      left: 49.75%;
      top: 85.5%;
      font-size: 1vw;
      font-family: "Baskerville URW", sans-serif;
      color: var(--color-gray-dark);
      transform: translate(-50%, -50%);
      @media (max-width: 640px) {
        color: black;
        width: 100%;
        background: white;
        bottom: 0;
        top: 100%;
        /* left: 1rem; */
        font-size: 1rem;
      }
    }
    h6 {
      width: 100%;
      max-width: 100%;
      margin: auto;
      padding: 0.5rem;
    }
  }
  .before-after__wrapper {
    width: fit-content;
    margin: 2rem auto;
    margin-left: -52px;
    width: 100%;
    max-width: 750px;
  }
  .before-after {
    width: 80%;
    max-width: 750px;
    margin: auto;
    padding-left: 52px;
    // height: 300px;
    position: relative;
    // transition: width 0.5s;
    // max-width: 98vw;
    // transform: translateX(-41px);
    img {
      max-width: 100%;
      max-height: 90vh;
    }
    .after {
      height: 100%;
      position: relative;
      width: 100%;
      left: 0px;
      top: 0px;
      z-index: 2;
      transition: all 0.5s ease-in-out;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .before {
      display: flex;
      height: 100%;
      position: absolute;
      left: 54px;
      top: 0px;
      width: 100%;
      transition: all 0.5s ease-in-out;
      @media (max-width: 1080px) {
        animation: shuffleCardBack 1s forwards;
      }
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      img {
        object-fit: cover;
        height: 100%;
        max-height: 90vh;
      }
    }
    .before-btn {
      display: block;
      //   position: relative;
      z-index: 2;
      width: 54px;
      background: var(--color-PE-blue);
      color: white;
      font-weight: 700;
      font-family: "Franklin Gothic Book";
      border: none;
      div {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        transform: rotate(-90deg);
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          transition: all 0.5s ease-in-out;
        }
      }
      transition: all 0.5s ease-in-out;

      &:hover {
        filter: brightness(85%);
      }
    }
    &.active {
      // transform: translateX(-50%);
      .before {
        left: 50%;

        @media (max-width: 1080px) {
          animation: shuffleCard 1s forwards;
        }
      }
      .after {
        transform: translateX(-50%);
        @media (max-width: 1080px) {
          transform: translateX(0);
        }
      }
    }
  }
}
@keyframes shuffleCard {
  50% {
    left: 100%;
  }
  100% {
    left: 54px;
    z-index: 2;
  }
}
@keyframes shuffleCardBack {
  0% {
    z-index: 2;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 54px;
    z-index: 1;
  }
}
