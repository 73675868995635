.the-wharf-at-long-last {
  .hero-wrapper {
    display: block;
    position: relative;
  }
  .hero {
    width: 100%;
    height: var(--hero-height);
    &-image {
      width: 100%;
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    &-text {
      position: absolute;
      top: 4%;
      left: 9%;
      width: auto;
      height: auto;
      white-space: nowrap;
    }
    h1 {
      font-family: "Baskerville Poster PT", sans-serif;
      position: absolute;
      padding: 0;
      color: black;
      left: 4rem;
      top: 0;
      font-size: 8.5rem;
      font-weight: 400;
      font-style: italic;
      line-height: 0.96;
      @media (max-width: 504px) {
        font-size: 23vw;
        left: 14vw;
      }
    }
    h2 {
      font-family: Franklin Gothic Book, sans-serif;
      position: absolute;

      color: var(--color-PE-blue);
      // left: 6%;
      top: 0;
      font-size: 3rem;
      font-weight: 400;
      @media (max-width: 504px) {
        font-size: 10vw;
      }
    }
    .tagline {
      position: absolute;
      left: 6rem;
      top: 6.5rem;
      // transform: scaleY(1.6);
      letter-spacing: 1px;
      font-size: 2.6rem;
      font-weight: 800;
      font-family: Franklin Gothic Demi Cond, sans-serif;
      color: var(--color-PE-blue);
      text-transform: uppercase;
      @media (max-width: 504px) {
        font-size: 7vw;
        top: 17.5vw;
        left: 20vw;
      }
    }
    .by-line {
      position: absolute;
      left: 14.5rem;
      top: 0;
      font-size: 1rem;
      font-family: "Baskerville URW", sans-serif;
      color: var(--color-gray);
      @media (max-width: 504px) {
        left: 30vw;
        top: 24vw;
        font-size: 1rem;
      }
    }
    h4 {
      max-width: 500px;
      font-size: 2rem;
      font-family: "Baskerville URW", sans-serif;
      font-style: italic;
      color: white;
      font-weight: 400;
      position: absolute;
      bottom: 80px;
      right: 50px;
      @media (max-width: 504px) {
        right: 0;
        font-size: 7vw;
        bottom: 5rem;
      }
      @media (max-width: 379px) {
        bottom: 8rem;
        font-size: 1.25rem;
      }
    }
    h6 {
      width: 100%;
      max-width: 100%;
      margin: 0 1rem;
      max-width: 100%;
    }
  }

  .section {
    position: relative;
    .caption {
      color: var(--color-gray-dark);
      margin-top: 4rem;
      margin-bottom: 1rem;
    }
    h4 {
      font-family: Franklin Gothic Demi Cond, sans-serif;
      color: gray;
      width: 100%;
      max-width: 750px;
      // margin: 1rem auto;
    }
    .row-2 {
      position: relative;
      width: 100%;
      max-width: 750px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: auto;
      padding-bottom: 2rem;

      div {
        width: 48%;
        .LazyLoad {
          width: 100%;
          img {
            width: 100%;
          }
        }
        @media (max-width: 490px) {
          width: 100%;
        }
      }
    }
    .image-collage {
      display: flex;
      width: 100%;
      .images {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 98%;
          margin-top: 1rem;
        }
        .row-2 {
          margin: 0;

          .images-single:nth-of-type(1) {
            width: 37%;
          }
          .images-single:nth-of-type(2) {
            width: 60%;
          }
          .images-single {
            margin: 0;
            @media (max-width: 490px) {
              width: 100% !important;
            }
          }
        }
      }
      .captions {
        width: 30%;
      }
    }
    &.building-structures {
      width: 100%;
      padding: 4%;
      margin: 0;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      background: #d3d8ed;
      .perkins-eastman {
        font-family: "Baskerville Poster PT", sans-serif;
        color: black;

        font-size: 2rem;
        font-weight: 400;
        font-style: italic;
        text-transform: none;
        margin-right: 1rem;
      }
      h4 {
        font-family: Franklin Gothic Book, sans-serif;
        color: var(--color-PE-blue);
        // left: 6%;
        // top: 7%;
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      .image-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .single-image {
          width: 50%;
          padding: 2%;

          @media (max-width: 490px) {
            width: 100%;
          }

          img {
            max-height: 300px;
            object-fit: cover;
          }
        }
      }
    }
    .hidden-wharf {
      color: var(--color-PE-blue);
    }
  }
}
