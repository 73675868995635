.nav-btns {
  .prev-btn,
  .next-btn {
    position: fixed;
    bottom: 15px;
    border-radius: 50%;
    font-size: 3rem;
    // background: var(--color-PE-blue);
    color: white;
    -webkit-text-stroke: 2px var(--color-PE-blue-dark);

    z-index: 1000;
    width: 75px;
    height: 75px;
    border: 2px var(--color-PE-blue-dark) solid;
    box-shadow: 0 0 0 1px white;
    text-align: center;
    font-family: monospace;

    transition: all 0.2s ease-in-out;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 50%;
    //   backdrop-filter: blur(2px);
    //   z-index: -1;
    //   -webkit-backdrop-filter: blur(2px); /* Safari 6.0 - 9.0 */
    // }
    &:hover {
      text-decoration: none;
      filter: brightness(60%);
    }
  }
  .prev-btn {
    left: 10px;
  }
  .next-btn {
    right: 10px;
  }
}
