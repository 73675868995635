.its-showtime {
  .hero {
    width: 100%;
    height: var(--hero-height);
    display: block;
    position: relative;
    &-images {
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
        // object-position: 50% 30%;
        object-position: 58% 36%;
      }
    }
    &-text {
      width: auto;
      position: absolute;
      top: 4%;
      left: 4%;
      // transform: translate(-50%, 0);
      // position: relative;
      // display: flex;
      // height: 100%;
      // flex-direction: column;
      // justify-content: center;

      h1 {
        font-family: "Baskerville Poster PT", sans-serif;
        color: white;
        font-style: italic;
        display: block;
        font-size: 14vw;
        font-weight: 400;
        margin: 0;
        @media (max-width: 580px) {
          font-size: 18vw;
          top: 4%;
          position: absolute;
        }
        @media (max-width: 380px) {
          font-size: 5rem;
        }
      }
      h2 {
        color: var(--color-PE-yellow);
        display: block;
        font-family: Franklin Gothic Demi Cond;
        font-size: 6vw;
        position: absolute;
        top: 8%;
        left: 10%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        white-space: nowrap;
        @media (max-width: 580px) {
          transform: translate(0%, -50%);
          font-size: 3rem;
        }
        // span {
        //   color: var(--color-PE-gray);
        //   font-weight: 400;
        //   font-size: 4vw;
        //   font-family: sans-serif;
        //   text-transform: lowercase;
        // }
      }
    }
  }
  h5 {
    margin: 0;
    padding: 0 4%;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  .static {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // h6 {
    //   color: var(--color-PE-gray);
    //   font-family: "Baskerville URW", sans-serif;
    //   font-style: italic;
    //   display: block;
    //   // width: 60%;
    //   // max-width: 500px;
    //   // margin-left: 30%;
    //   font-size: 1.5rem;
    //   text-align: center;
    // }
    h6 {
      margin-top: 0;
    }
    .by-line {
      color: var(--color-gray);
      font-style: normal;
      font-size: 1rem;
      margin-top: 1rem;
      font-family: "Baskerville URW", sans-serif;
      margin: 1rem auto;
      width: 100%;
    }
    .caption {
      color: gray;
      color: var(--color-PE-gray);
      font-family: "Baskerville URW", sans-serif;
      font-style: italic;
      display: block;
      font-size: 2rem;
      font-weight: 400;
    }
  }
}
