@use "sass:math";
body {
  margin: 0;
  overflow-x: hidden;
}
.home {
  // background: url("https://res.cloudinary.com/nickpug/image/upload/v1664473356/The%20Narrative/confetti_skeotx.jpg");
  // overflow: hidden;
  // height: calc(100vh - 96px);
  width: 100vw;
  background-size: cover;
  transition: all 0.2s ease-in-out;

  a {
    color: white;
    &:hover {
      filter: brightness(60%);
    }
  }
}
.fontawesome {
  color: white;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: brightness(60%);
  }
}

.cover,
#cover-item {
  // transform: translateY(-50%);
  // animation: left-right 1.2s linear 0.2s 1;
  // mix-blend-mode: screen;
  img {
    width: 100%;
    object-fit: cover;
    // filter: drop-shadow(0 0 0.75rem grey);
  }
}

.cover {
  position: relative;
  margin: auto;
  width: 100vw;
  min-width: 100vw;
  // padding: 0 4vw;
  // top: 0px;
  // left: 0;
  height: calc(100vh - 56px);
  overflow: hidden;
  img {
    object-position: center;
    height: 100%;
  }
  .text {
    text-transform: capitalize;
    width: 350px;
    color: white;
    position: absolute;
    left: calc(4vw + 30px);
    top: 3vh;
    @media (max-width: 749px) {
      left: 0.5rem;
      max-width: 250px;
    }
    .logo {
      width: 100%;
      margin-bottom: 4rem;
    }

    .highlight {
      font-family: Franklin Gothic Demi Cond, "Arial Narrow", Arial, sans-serif;
      line-height: 1.13;
      font-weight: 700;
      font-size: 2.25rem;
      margin-bottom: 5.5rem;
      @media (max-width: 749px) {
        font-size: 1.75rem;
        margin-bottom: 3.75rem;
        margin-top: 0;
      }
    }
  }
  .title {
    position: absolute;
    right: calc(4vw + 10px);
    top: 4vh;
    width: 14rem;
    @media (max-width: 749px) {
      width: 30vw;
    }
    img {
      height: auto;
    }
  }
  .issue-wrapper {
    position: absolute;
    top: 60vh;
    right: calc(4vw + 10px);
    width: 13.7rem;
    color: white;
    @media (max-width: 749px) {
      width: 30vw;
      right: calc(4vw + 6px);
    }
    .issue {
      font-family: Franklin Gothic Medium, "Arial Narrow", Arial, sans-serif;
      letter-spacing: 4.2px;
      margin-bottom: 0.5rem;
      white-space: nowrap;
      font-weight: 700;
      font-size: 2rem;
    }
  }
}
#cover-item {
  position: absolute;

  width: 80%;
  max-width: 650px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  img {
    transform: rotate(90deg);
  }
}
.human-by-design {
  position: absolute;
  width: 33vw;
  min-width: 300px;
  top: 350px;
  left: 55%;
  background: var(--color-PE-blue);
  padding: 10px;
  // transform: translateY(-50%);
  // animation: left-right 1.2s linear 0.2s 1;

  img {
    width: 100%;
    object-fit: cover;
    // filter: drop-shadow(0 0 0.75rem grey);
  }
}
.welcome {
  color: white;
  background: var(--color-PE-blue);
  // margin-left: 45%;
  // margin-right: 4%;
  // margin-top: calc(100vh - 72px);
  padding: 2rem 0;
  p {
    font-family: Franklin Gothic Book, "serif";
  }
  h1 {
    color: white;
    margin-bottom: 2rem;
    font-family: Franklin Gothic Demi cond, "serif";
  }
  h6 {
    font-family: Franklin Gothic Demi Cond, "serif";
    font-weight: 700;
    font-size: 1.15rem;
  }
  h4 {
    font-family: Franklin Gothic Demi Cond, "serif";
    color: var(--color-PE-yellow);
    margin-bottom: 0;
    font-weight: 700;
  }
  h5 {
    font-family: "Basekerville URW", "serif";
  }
  display: flex;
  flex-wrap: wrap;

  .left,
  .right {
    width: 50%;
    padding: 2rem 6vw;
  }
  .right {
    .single-image {
      margin-bottom: 2rem;
      position: relative;
      display: flex;
      flex-direction: column;
      .image-caption {
        color: black;
        width: 100%;
        text-align: center;
        // position: absolute;
        // left: 0;
        // bottom: 0;
        margin: 0;
        background: rgba(250, 250, 250, 0.6);
        font-family: "baskerville URW";
      }
      .copyright {
        margin: 0;
      }
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 823px) {
    .left,
    .right {
      width: 100%;
    }
  }
}
.wrapper {
  position: absolute;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  //   background: #0054a6;
  // background: rgba(0, 84, 166, 0.75);
  background: darkblue;
  // background: white;
  overflow: hidden;
}
