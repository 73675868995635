@use "styles/NavBtns.scss";
@use "styles/home.scss";
@use "styles/toc.scss";
@use "styles/mary-jean-eastman-paves-the-way.scss";
@use "styles/navigating-the-future.scss";
@use "styles/the-age-of-collaboration.scss";
@use "styles/the-wharf-at-long-last.scss";
@use "styles/quick-on-the-draw.scss";
@use "styles/its-showtime.scss";
@use "styles/transformational-designs.scss";
@use "styles/going-car-free.scss";
@use "styles/the-third-act.scss";
@use "styles/the-annual-excellence-portfolio.scss";
@use "styles/take-five.scss";

// @import "https://use.typekit.net/eob6vfn.css";
// @import "styles/FRADMCN.TTF";

@font-face {
  font-family: Franklin Gothic Demi Cond;
  src: url(styles/FRADMCN.TTF);
}
@font-face {
  font-family: Franklin Gothic Medium;
  src: url(styles/framd.ttf);
}
@font-face {
  font-family: Franklin Gothic Book;
  src: url(styles/FRABK.TTF);
}

* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
