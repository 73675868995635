html {
  --color-blue-dark: #3f3697;
  --color-blue-medium: #344fa1;
  --color-blue-light: #3d84b8;
  --color-blue-light-90: #3d84b890;

  --color-yellow: #fcc12f;
  --color-yellow-dark: #b5871e;

  --color-cream: #f0ebcc;
  --color-background: #24366b;
  --color-gray: #58595b;
  --color-gray-dark: #3c3c3c;

  --color-PE-orange: #ff530d;
  --color-PE-blue: #0054a6;
  --color-PE-blue-dark: #04154f;
  --color-PE-yellow: #ffc72c;
  --color-PE-green: #00857d;
  --color-PE-gray: #999999;

  --hero-height: calc(100vh - 56px);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Baskerville URW" "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgba(0, 0, 0, 0.9); */
  background: var(--color-PE-blue-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p,
li {
  font-size: 18px;
  font-family: "Baskerville URW", sans-serif;
  line-height: 1.5;
  max-width: 750px;
  margin: 1rem auto;
}
.static,
.sticky-element {
  // h3 {
  //   width: 100%;
  //   max-width: 700px;
  //   margin: auto;
  // }
  h3,
  h4 {
    font-family: Franklin Gothic Demi Cond, sans-serif;
    color: gray;
    width: 100%;
    max-width: 750px;
    margin: 1rem auto;
  }
}

h1 {
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
h6 {
  max-width: 750px;
  margin: 1rem auto;
}
strong {
  font-family: "Franklin Gothic Demi Cond", Sans-serif;
}
.caption {
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.25;
  font-size: 18px;
}
img {
  width: 100%;
}
@media (max-width: 600px) {
  .bg {
    height: 100vh;
  }
  .bg img {
    height: 100%;
  }
}
.bg {
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  max-height: 100vh;
  overflow: hidden;
}
.bg::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgb(0, 0, 0); */
  opacity: 0.8;
}
.bg img {
  width: 100%;
}
.hide {
  display: none;
}
// .slide-enter,
// .slide-exit {
//   transition: transform 1000ms ease-out;
// }

// .slide-enter {
//   transform: translateX(100%);
//   position: absolute;
//   /* min-height: 90vh; */
// }

// .slide-enter.slide-enter-active {
//   transform: translateX(0%);
// }

// .slide-exit {
//   position: absolute;

//   transform: translateX(0%);
// }

// .slide-exit-active {
//   transform: translateX(-100%);
// }
.page {
  margin-top: 56px;
}
.section {
  margin: 0 1rem;
}

.pageSlider-exit > .page {
  z-index: 1;
}

.pageSlider-enter > .page {
  z-index: 10;
}

.pageSlider-enter.left > .page {
  transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.right > .page {
  transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.left > .page {
  transform: translate3d(-100%, -100%, 0);
}

.pageSlider-exit.right > .page {
  transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.pageSlider-enter-active > .page {
  transform: translate3d(0, 0, 0);
  transition: all 800ms ease-in-out;
}

.switch {
  position: relative;
  width: 100vw;
  height: fit-content;
  margin-top: 56px;
}
.menu {
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100vw;
}
.navbar-brand {
  padding: 0;
}
.navbar-toggler {
  margin-left: auto;
  margin-right: 1rem;
}
.navbar {
  background: var(--color-PE-blue);
  font-family: Franklin Gothic Demi Cond, sans-serif;
  .dropdown-menu .nav-item {
    color: black !important;
  }
}
// .dropdown {
//   // @media (min-width: 992px) {
//   &:hover {
//     .dropdown-menu {
//       display: block;
//     }
//   }
//   // }
//   // .nav-children {
//   //   display: none;
//   //   background: var(--color-PE-blue);
//   //   @media (min-width: 992px) {
//   //     position: absolute;
//   //   }
//   //   .nav-child {
//   //     text-align: start;
//   //   }
//   // }
// }
@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .nav-link {
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}
.logo-image {
  width: 145px;
}
.footer {
  height: 25px;
}
/* .content-wrapper {
  display: flex;
} */
/* .switch {
  display: flex;
  flex: 1 1 auto;
  background: blue;
} */
/* .content-wrapper {
  display: flex;
  flex: 1 1 auto;
  position: relative;
} */
/* 
.home {
  overflow: hidden;
  margin: 0;
  padding: 0;
  max-width: 100%;
} */

/* .wrapper {
  position: relative;
  display: block;
  flex: 1 1 auto;
} */

.content-wrapper {
  position: absolute;
  width: 100%;
}
.content {
  background: var(--color-blue-light-90);
  z-index: 2;
  border-radius: 25px;
  position: relative;
  max-width: 500px;
  margin: auto;
  /* top: 0; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.arrow {
  color: white;
}
.arrow:hover {
  color: rgba(0, 0, 0, 0.4);
}
.project-link {
  color: var(--gray-dark);
}
.project-link:hover {
  color: black;
}
.home-link {
  color: rgb(140, 140, 140);
  font-weight: 700;
}
.home-link:hover {
  color: rgb(30, 30, 30);
}
.read-less {
  height: 92px !important;
  position: relative;
}
.read-less:before {
  content: "...";
  display: block;
  position: absolute;
  top: 100%;
}
/* .about .row {
  padding-left: 10%;
  padding-right: 10%;
} */
.about p {
  line-height: 2;
}
.profile {
  max-width: 300px;
}
.app-link {
  color: var(--color-yellow);
}
.app-link:hover {
  color: var(--color-yellow-dark);
}
.app-nav {
  display: flex;
  flex-direction: column !important;
  margin-left: auto;
  text-align: end;
}
@media (min-width: 768px) {
  .app-nav {
    margin: auto;
    text-align: center;
    flex-direction: row !important;
  }
}
.cap {
  font-weight: 700;
  line-height: 1.25;
  font-size: 18px;
}
footer {
  z-index: 50;
  height: 6vh;
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.page {
  position: relative;
  background: white;
  // padding-bottom: 4rem;
}

.hero {
  h2 {
    font-family: "Baskerville Poster PT", "Sans-serif";
  }
  h1 {
    font-family: Franklin Gothic Demi Cond, "Sans-serif";
  }
  &-text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.indent {
  padding-left: 16px;
}
.two-col {
  display: flex;

  .column {
    padding: 0;
    padding-top: 1rem;
  }
  .column:nth-of-type(1) {
    padding-right: 0.5rem;
  }
  .column:nth-of-type(2) {
    padding-left: 0.5rem;
  }
}
.sticky-element {
  padding: 0;
  width: 40%;
  position: sticky;
  position: -webkit-sticky;
  height: fit-content;
  top: 5vw;
  left: 0;
}
.static {
  // width: 60%;
  max-width: 800px;
  // padding: 2rem;
  margin: auto;

  img {
    max-width: 750px;
    max-height: 90vh;
    object-fit: contain;
    margin: auto;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .images-single {
    width: 100%;
    max-width: 750px;
    margin: 1rem auto;
  }
  .quote {
    color: var(--color-gray);
    position: relative;
    display: block;
    margin-top: 3rem;
    margin-bottom: 2rem;
    &::before {
      content: open-quote;
      display: block;
      position: absolute;
      font-size: 11rem;
      left: -15px;
      top: -102px;
      font-family: Franklin Gothic Demi Cond, "Sans-serif";
      color: #d3b7ca;
      z-index: 1;
    }
    &::after {
      visibility: hidden;
      content: close-quote;
      display: block;
    }
    p {
      font-family: "Baskerville URW", "Sans-serif";
      font-size: 1.75rem;
      line-height: 1.15;
      font-style: italic;
      z-index: 2;
      position: relative;
      margin-bottom: 0;
    }
    h5 {
      font-family: Franklin Gothic Medium, "Sans-serif";
      font-weight: 700;
      text-align: right;
    }
  }
}
.parallax {
  /* The image used */
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
  //   rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

  box-shadow: 0px 0px 13px 6px rgba(0, 0, 0, 0.77) inset;
  -webkit-box-shadow: 0px 0px 13px 6px rgba(0, 0, 0, 0.77) inset;
  -moz-box-shadow: 0px 0px 13px 6px rgba(0, 0, 0, 0.77) inset;
  min-height: 250px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  //   background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 80vh;
  background-size: cover;
  &-caption {
    padding: 0 4%;
    margin: 0;
    margin-bottom: 1rem;
    text-align: center;
    max-width: 100%;
  }
}

.image-cluster {
  img {
    width: 100%;
    object-fit: cover;
  }
}
