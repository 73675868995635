.a-perkins-eastman-city {
  width: 100vw;
  height: var(--hero-height);
  display: block;
  position: relative;
  //   overflow: hidden;
  overflow-x: hidden;
  overflow-y: clip;

  .hero {
    background: url("../img/perkins-eastman-city/Blue_wharf_adjusted_sdobx4.jpg");
    height: 100%;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    br {
      @media (max-width: 630px) {
        display: none;
      }
    }
    .left {
      width: 50%;
      height: 100%;
      display: block;
      // text-align: end;
      @media (max-width: 630px) {
        width: 100%;
        height: 50vh;
      }
      h1 {
        font-family: "Baskerville Poster PT", sans-serif;
        color: var(--color-PE-yellow);
        font-style: italic;
        margin-left: 6%;
        margin-top: 3rem;
        width: fit-content;
        @media (max-width: 630px) {
          margin: auto;
        }

        .imagine {
          font-size: 3rem;
          margin-right: 18rem;
          //   transform: translateY(10rem);
          line-height: 0.7;
          z-index: 2;
          position: relative;
          @media (max-width: 1200px) {
            font-size: 5vw;
            margin-right: 0;
          }
          @media (max-width: 630px) {
            font-size: 3rem;
          }
        }
        .PE {
          width: auto;
          color: white;
          font-family: Franklin Gothic Demi Cond, sans-serif;
          display: block;
          text-transform: uppercase;
          font-style: normal;
          font-size: 4rem;
          line-height: 0.3;
          // margin-left: 4rem;
          @media (max-width: 1200px) {
            font-size: 5vw;
          }
          @media (max-width: 630px) {
            font-size: 3rem;
          }
        }
        .city-text {
          font-size: 12rem;
          display: block;
          line-height: 0.8;
          margin-left: 10.5rem;
          @media (max-width: 1200px) {
            font-size: 12vw;
            margin-left: 18vw;
          }
          @media (max-width: 630px) {
            font-size: 7rem;
            margin-left: 36vw;
          }
        }
      }
    }
    .right {
      width: 50%;
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      position: relative;
      font-family: Franklin Gothic Medium Cond, sans-serif;
      color: white;
      padding-bottom: 3rem;
      @media (min-width: 630px) and (max-width: 1100px) {
        padding-bottom: 4rem;
        padding-left: 5rem;
        width: 50%;
      }
      @media (max-width: 630px) {
        width: 100%;
        height: 50vh;
        padding-bottom: 4rem;
      }
      img {
        max-width: 300px;
        margin-bottom: 2rem;
        // margin-left: 96px;
        @media (max-width: 775px) {
          max-width: 200px;
        }
        @media (max-width: 630px) {
          width: 60%;
          margin-bottom: 0.5rem;
        }
      }
      .credit {
        font-size: 1.5rem;
        text-align: center;
        margin: 0;
        @media (max-width: 630px) {
          font-size: 14px;
        }
        &-sm {
          font-size: 1rem;
          margin: 0;
          line-height: 1;
          text-align: center;
          @media (max-width: 630px) {
            font-size: 14px;
          }
        }
      }
    }
    // .left,
    // .right {
    //   @media (max-width: 630px) {
    //     width: 100%;
    //     height: 50vh;
    //   }
    // }
  }
  .btn-wrapper,
  .btn-wrapper__city {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .btn-wrapper {
    button {
      background: var(--color-PE-blue);
      color: white;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 2px solid var(--color-PE-yellow);
      font-family: "Baskerville Poster PT", sans-serif;
      font-style: italic;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 2rem;
      line-height: 1;
      transition: all 0.5s ease-in-out;
      box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.12),
        0px 3px 6px 0px rgba(0, 0, 0, 0.22), 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
      @media (max-width: 630px) {
        width: 150px;
        height: 150px;
      }
      &:hover {
        filter: brightness(0.7);
      }
    }
  }
  .btn-wrapper__city {
    top: 96%;
    transition: all 0.8s ease-in-out;
    z-index: 100;
    button {
      background: var(--color-PE-blue);
      color: white;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid white;
      box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.12),
        0px 3px 6px 0px rgba(0, 0, 0, 0.22), 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
      transition: all 0.4s ease-in-out;
      &:hover {
        filter: brightness(0.7);
      }
    }

    &.active {
      top: 10%;
    }
  }
  .panels {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    // pointer-events: none;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    &-left,
    &-right {
      display: flex;
      width: 100%;
      height: 100%;
      background: white;
      transition: all 1s ease-in-out;
      justify-content: center;
      align-items: center;
      background-size: cover;
      p {
        font-size: 14px;
      }
      @media (min-width: 831px) {
        width: 50%;
        p {
          font-size: 16px;
          margin: 0;
        }
      }
      .buildings {
        position: absolute;
        width: 100%;
        bottom: 0;
        max-height: 300px;
        overflow: hidden;
        // display: none;

        @media (max-width: 1200px) {
          display: none;
        }
        img {
          height: 257px;
          object-fit: fill;
        }
      }
      .text {
        max-width: 500px;
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        width: 90%;
        padding: 4%;
        background-color: rgba(28, 21, 110, 0.4);
        color: white;
        font-family: Franklin Gothic Book, sans-serif;
        // max-width: 80%;
        display: block;
        // position: relative;
        @media (max-width: 430px) {
          top: 56%;
        }
        h3 {
          color: var(--color-PE-yellow);
        }
        border-left: 1px solid white;
        border-right: 1px solid white;
      }
    }
    &-left {
      transform: translateX(-100%);
      background: url("../img/perkins-eastman-city/canal_left_rjowph.png");
      background-size: cover;
      background-position: 100% 0;

      .text {
        border-bottom: 1px solid white;

        &::after {
          content: "";
          width: 35%;
          height: 1px;
          position: absolute;
          right: 0;
          top: 0;
          background: white;
          @media (max-width: 1107px) {
            display: none;
          }
        }
        h3 {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateY(-50%);
          @media (max-width: 1107px) {
            // transform: translate(-50%, -50%);
            text-align: center;
            width: 100%;
            font-size: 2rem;
          }
          @media (max-width: 409px) {
            font-size: 1.5rem;
          }
        }
      }
    }
    &-right {
      transform: translateX(100%);
      background: url("../img/perkins-eastman-city/canal_right_wvzvxp.png");
      background-size: cover;
      .text {
        border-top: 1px solid white;
        &::after {
          content: "";
          width: 35%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
          background: white;
          @media (max-width: 1107px) {
            display: none;
          }
        }
        h3 {
          position: absolute;

          bottom: 0;
          right: 0;
          transform: translateY(70%);
          @media (max-width: 1107px) {
            // transform: translate(-50%, -50%);
            text-align: center;
            width: 100%;
            font-size: 2rem;
          }
          @media (max-width: 409px) {
            font-size: 1.5rem;
          }
        }
      }
    }
    &.active {
      .panels-left,
      .panels-right {
        transform: translateX(-0%);
      }
    }
  }
  .city {
    width: 100vw;
    height: 100%;
    overflow-x: scroll;
    transform: translateY(100%);
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    transition: all 0.8s ease-in-out;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
    img {
      width: auto;
      height: var(--hero-height);
      object-fit: cover;
    }
    &.active {
      transform: translateY(0);
    }
    .img-wrapper {
      width: fit-content;
      position: relative;
      display: block;
    }
    .story {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-around;
      // margin-left: -18vh;
      p {
        // max-width: 300px;
        max-width: 300px;
        margin-top: 2.5%;
        font-family: Fanklin Gothic Medium Cond, serif;
        line-height: 1.15;
        font-size: 1rem;
        // margin-left: 25vh;
      }
    }
    .projects {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      &-single {
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        &__hover-area {
          // background: rgba(238, 86, 86, 0.4);
          position: absolute;
          display: block;
          pointer-events: all;
          transition: all 0.4s ease-in-out;
        }

        &__text {
          position: absolute;
          display: block;
          width: 190px;
          transition: all 0.4s ease-in-out;

          // pointer-events: all;
          h3 {
            font-size: 14px;
            // left: 100%;
            // position: absolute;
            display: block;
            margin: 0;
          }
          h4 {
            font-size: 12px;
            margin: 0;
          }
          display: none;
          opacity: 0;
          &::before {
            content: "";
            background: var(--color-PE-orange);
            display: block;
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            left: 0;
            top: 120%;
          }
          &::after {
            content: "";
            background: var(--color-PE-orange);
            display: block;
            position: absolute;
            width: 1px;
            height: 10px;
            left: 0;
            top: 120%;
            left: 2px;
          }
        }
        .projects-single__hover-area:hover + .projects-single__text {
          display: block;
          opacity: 1;
        }
      }
      #cleveland-park-library {
        width: 10vh;
        .projects-single__hover-area {
          width: 100%;
          height: 40%;
          top: 60%;
          display: block;
        }
        .projects-single__text {
          top: 38%;
          left: 1vh;
          &::after {
            height: 10vh;
          }
        }
      }
      #target-field-station {
        width: 22vh;
        left: calc(14vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 18%;
          top: 45%;
          display: block;
        }
        .projects-single__text {
          top: 28%;
          left: 10vh;
          &::after {
            height: 15vh;
          }
        }
      }
      #banneker-hs {
        width: 10vh;
        left: calc(20vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 18%;
          top: 63%;
          display: block;
        }
        .projects-single__text {
          top: 30vh;
          left: 6vh;
          &::after {
            height: calc(30vh - 2rem);
          }
        }
      }
      #collection-14 {
        width: 16vh;
        left: calc(30vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 28%;
          top: 55%;
          display: block;
        }
        .projects-single__text {
          top: 34%;
          left: 10vh;
          &::after {
            height: 15vh;
          }
        }
      }
      #marinhealth {
        width: 8vh;
        left: calc(52vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 12%;
          top: 65%;
          display: block;
        }
        .projects-single__text {
          top: 43%;
          left: 4vh;
          &::after {
            height: 20vh;
          }
        }
      }
      #hudson {
        width: 7vh;
        left: calc(60vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 28%;
          top: 46%;
          display: block;
        }
        .projects-single__text {
          top: 35%;
          left: 4vh;

          &::after {
            height: 8vh;
          }
        }
      }
      #calpoly {
        width: 6vh;
        left: calc(66vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 8%;
          top: 68%;
          display: block;
        }
        .projects-single__text {
          top: 55%;
          left: 3vh;
          &::after {
            height: 8vh;
          }
        }
      }
      #everton {
        width: 24vh;
        left: calc(70vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 15%;
          top: 63%;
          display: block;
        }
        .projects-single__text {
          top: 45%;
          left: 10vh;
          &::after {
            height: 15vh;
          }
        }
      }
      #jewish-services {
        width: 8vh;
        left: calc(93vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 6%;
          top: 72%;
          display: block;
        }
        .projects-single__text {
          top: 60%;
          left: 4vh;

          &::after {
            height: 7.5vh;
          }
        }
      }
      #woodside {
        width: 9vh;
        left: calc(104vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 6%;
          top: 73%;
          display: block;
        }
        .projects-single__text {
          top: 42%;
          left: 4vh;
          &::after {
            height: 28vh;
          }
        }
      }
      #koch {
        width: 11vh;
        left: calc(112vh - 56px);
        z-index: 4;
        .projects-single__hover-area {
          width: 100%;
          height: 33%;
          top: 46%;
          display: block;
        }
        .projects-single__text {
          top: 25%;
          left: 5vh;
          &::after {
            height: calc(8vh - 1rem);
          }
        }
      }
      #wharf {
        width: 26vh;
        left: calc(113vh - 56px);
        z-index: 3;
        .projects-single__hover-area {
          width: 100%;
          height: 23%;
          top: 65%;
          display: block;
        }
        .projects-single__text {
          top: 50%;
          left: 14vh;
          &::after {
            height: 13vh;
          }
        }
      }
      #empire {
        width: 25vh;
        left: calc(138vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 34%;
          top: 51%;
          display: block;
        }
        .projects-single__text {
          top: 39%;
          left: 8vh;
          &::after {
            height: 10vh;
          }
        }
      }
      #one-hotel {
        width: 12vh;
        left: calc(161vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 23%;
          top: 54%;
          display: block;
        }
        .projects-single__text {
          top: 45%;
          left: 4vh;
          &::after {
            height: 7vh;
          }
        }
      }
      #tkts {
        width: 22vh;
        left: calc(157vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 16%;
          top: 75%;
          display: block;
        }
        .projects-single__text {
          top: 50%;
          left: 19vh;
          &::after {
            height: 20vh;
          }
        }
      }
      #rutgers {
        width: 22vh;
        left: calc(184vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 32%;
          top: 50%;
          display: block;
        }
        .projects-single__text {
          top: 35%;
          left: 7vh;
          &::after {
            height: 15vh;
          }
        }
      }
      #tenement {
        width: 15vh;
        left: calc(208vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 22%;
          top: 58%;
          display: block;
        }
        .projects-single__text {
          top: 47%;
          left: 4vh;
          &::after {
            height: 10vh;
          }
        }
      }
      #thrugood {
        width: 8.5vh;
        left: calc(232vh - 100px);
        .projects-single__hover-area {
          width: 100%;
          height: 32%;
          top: 46%;
          display: block;
        }
        .projects-single__text {
          top: 33%;
          left: 4vh;
          &::after {
            height: 7vh;
          }
        }
      }
      #aegis {
        width: 11vh;
        left: calc(230vh - 53px);
        .projects-single__hover-area {
          width: 100%;
          height: 22%;
          top: 60%;
          display: block;
        }
        .projects-single__text {
          top: 47%;
          left: 6vh;
          &::after {
            height: 8vh;
          }
        }
      }
      #gonzaga {
        width: 8vh;
        left: calc(243vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 10%;
          top: 68%;
          display: block;
        }
        .projects-single__text {
          max-width: 200px;
          top: 45%;
          &::after {
            height: 8vh;
          }
          left: 3vh;
          h3 {
            max-width: 100px;
          }
        }
      }
      #john-lewis {
        width: 17vh;
        left: calc(243vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 10%;
          top: 58%;
          display: block;
        }
        .projects-single__text {
          top: 30%;
          left: 5vh;
          &::after {
            height: 27vh;
          }
        }
      }
      #ashoka {
        width: 8vh;
        left: calc(254vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 10%;
          top: 68%;
          display: block;
        }
        .projects-single__text {
          top: 43%;
          left: 2.5vh;
          &::after {
            height: 20vh;
          }
        }
      }
      #mlk {
        width: 16vh;
        left: calc(260vh - 56px);
        .projects-single__hover-area {
          width: 100%;
          height: 40%;
          top: 48%;
          display: block;
        }
        .projects-single__text {
          top: 37%;
          left: 4vh;
          &::after {
            height: 5vh;
          }
          h3 {
            max-width: 100px;
          }
        }
      }
    }
  }
}
