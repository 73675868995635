.the-annual-excellence-portfolio {
  .hero {
    &-text {
      position: relative;
    }
    h1 {
      font-family: Franklin Gothic Book, sans-serif;
      color: var(--color-PE-blue);
      // left: 32%;
      // top: 31%;
      position: relative;
      font-size: 9rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 1rem;
      line-height: 0.8;
      @media (max-width: 730px) {
        font-size: 20vw;
        margin: auto;
        text-align: center;
      }
      span {
        font-family: "Baskerville Poster PT", sans-serif;
        color: black;
        // left: 32%;
        // top: 28%;
        position: relative;
        font-size: 4rem;
        font-weight: 400;
        font-style: italic;
        text-transform: capitalize;
        margin-left: -7rem;
        margin-bottom: -1rem;
        @media (max-width: 730px) {
          font-size: 10vw;
          margin-left: 0;
        }
      }
    }
    h6 {
      font-family: "Baskerville URW", sans-serif;
      color: gray;
      width: 100%;
      max-width: 750px;
      font-style: italic;
      font-size: 2rem;
      @media (max-width: 780px) {
        margin: 1rem;
      }
    }
  }
  .by-line {
    max-width: 750px;
    font-family: "Baskerville URW", sans-serif;
    color: gray;
    margin: auto;
    @media (max-width: 780px) {
      margin: 1rem;
    }
  }
  .images-single {
    display: flex;
    flex-wrap: wrap;
    background: var(--color-PE-blue);
    position: relative;
    color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    span {
      position: absolute;
      right: 0;
      top: 0;
      color: white;
    }
    &__img {
      width: 57%;
      display: block;
      position: relative;
      @media (max-width: 779px) {
        width: 100%;
      }
      #fairleigh,
      #banneker,
      #bassick {
        left: 10px;
        font-weight: 700;
      }
      #fairleigh {
        color: var(--color-PE-blue);
      }
      #banneker,
      #bassick {
        color: var(--color-PE-white);
      }
    }
    &__quote {
      width: 43%;
      // min-width: 300px;
      padding: 1rem;
      color: white;
      font-size: 1.5rem;
      font-style: italic;
      @media (max-width: 779px) {
        width: 100%;
      }
      p {
        margin: 0;
        line-height: 1.15;
      }
      h5 {
        font-size: 1rem;
        margin: 0;
      }
    }
  }
}
