.the-age-of-collaboration {
  .hero {
    //   width: 100%;
    display: block;
    position: relative;
    top: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: var(--hero-height);

    &-images {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      #collaboration-artwork {
        height: 100%;
        object-position: 90% 50%;
        object-fit: cover;
      }
      #pfeiffer {
        position: absolute;
        top: 52%;
        left: 10%;
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1160px) {
          width: 170px;
          top: 28%;
        }
        @media (max-width: 425px) {
          width: 150px;
          top: 28%;
          left: 3%;
        }
        img {
          position: relative;
        }
        &::before {
          content: "";
          position: absolute;
          width: 17vw;
          height: 17vw;
          background: rgba(255, 83, 13, 0.9);
          display: block;
          border-radius: 50%;
          @media (max-width: 1160px) {
            width: 200px;
            height: 200px;
          }
          @media (max-width: 425px) {
            width: 150px;
            height: 150px;
          }
        }
      }
      #via {
        position: absolute;
        top: 50%;
        left: 60%;
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1160px) {
          width: 150px;
          top: 32%;
        }
        @media (max-width: 425px) {
          width: 140px;
          top: 28%;
          right: 2%;
          left: auto;
        }
        img {
          position: relative;
        }
        &::before {
          content: "";
          position: absolute;
          width: 13vw;
          height: 13vw;
          background: rgba(255, 199, 44, 0.9);
          display: block;
          border-radius: 50%;
          @media (max-width: 1160px) {
            width: 150px;
            height: 150px;
          }
          @media (max-width: 425px) {
            width: 135px;
            height: 135px;
          }
        }
      }
      #meis {
        position: absolute;
        top: 74%;
        left: 27%;
        width: 12%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1160px) {
          width: 120px;
          left: 12%;
        }
        @media (max-width: 425px) {
          top: 72%;

          left: 5%;
        }
        img {
          position: relative;
        }
        &::before {
          content: "";
          position: absolute;
          width: 14vw;
          height: 14vw;
          background: rgba(0, 0, 0, 0.75);
          display: block;
          border-radius: 50%;
          @media (max-width: 1160px) {
            width: 140px;
            height: 140px;
          }
        }
      }
      #blta {
        position: absolute;
        top: 54.5%;
        left: 43%;
        width: 13%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1160px) {
          width: 170px;
          top: 47%;

          left: 50%;
          transform: translate(-50%, 0);
        }
        @media (max-width: 425px) {
          top: 41%;

          left: 41%;
        }
        img {
          position: relative;
        }
        &::before {
          content: "";
          position: absolute;
          width: 13vw;
          height: 13vw;
          background: rgba(0, 51, 160, 0.9);
          display: block;
          border-radius: 50%;
          @media (max-width: 1160px) {
            width: 120px;
            height: 120px;
          }
        }
      }
      #kha {
        position: absolute;
        bottom: -10%;
        left: 65%;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1160px) {
          bottom: -5%;
          width: 310px;
          left: 34%;
        }
        @media (max-width: 425px) {
          bottom: 7%;
          width: 250px;
          left: 34%;
        }
        img {
          position: relative;
        }
        &::before {
          content: "";
          position: absolute;
          width: 16vw;
          height: 16vw;
          background: rgba(127, 34, 104, 0.9);
          display: block;
          border-radius: 50%;
          @media (max-width: 1160px) {
            width: 200px;
            height: 200px;
          }
          @media (max-width: 425px) {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
    &-text {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      h2 {
        position: absolute;
        left: 50%;
        top: 23%;
        transform: translate(-135%, -100%);
        font-size: 7vw;
        font-weight: 400;

        font-family: "Baskerville Poster PT", sans-serif;
        font-style: italic;
        z-index: 2;
        @media (max-width: 1160px) {
          top: 5rem;
          font-size: 3rem;
        }
        @media (max-width: 570px) {
          font-size: 2.75rem;
          transform: translate(-90%, -100%);
          white-space: nowrap;
        }
      }
      h1 {
        position: absolute;
        top: 16%;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 9.45vw;
        text-transform: uppercase;

        font-family: "Franklin Gothic Demi Cond", Sans-serif;
        @media (max-width: 1160px) {
          top: 3rem;
          font-size: 4rem;
        }
        @media (max-width: 570px) {
          font-size: 2.5rem;
        }
        // -webkit-transform: scale(1, 1.2);
        // -moz-transform: scale(1, 1.2);
        // -o-transform: scale(1, 1.2);
        // transform: scale(1, 1.2);
      }
    }
  }
  .hero-right {
    width: 50%;
  }
  .tagline {
    font-family: "Baskerville URW", sans-serif;
    font-style: italic;
    // margin-top: calc(8vw - 2rem);
    font-size: 2.5rem;
    line-height: 1;
  }
  // .hero-whitespace {
  //   height: 9vw;
  //   display: block;
  //   position: relative;

  // }
  .by-line {
    // position: absolute;
    // left: 73%;
    // top: calc(85% - 12px);
    font-family: "Baskerville URW", sans-serif;
    color: gray;
    font-size: 16px;
  }
  .caption {
    margin-bottom: 0;
    font-weight: 700;
    line-height: 1.25;
    font-size: 18px;
  }
  .single-col {
    width: 100%;
    margin-top: 1rem;
  }
  .image-cluster {
    position: relative;
    width: 100%;
    padding: 0 4vw;

    height: fit-content;
    .images-wrapper {
      display: flex;
      flex-direction: column;
      .images-single {
        width: 50%;
        img {
          width: 100%;
          object-fit: cover;
        }
        h6 {
          font-size: 12px;
        }
      }
      .images-single:nth-of-type(1) {
        padding-right: 0.5rem;
      }
      .images-single:nth-of-type(2) {
        padding-left: 0.5rem;
      }
    }
  }
  #via-spire,
  #wuxi {
    width: 100%;
  }
  .static {
    h3 {
      // color: var(--color-PE-orange);
      font-family: "Franklin Gothic Demi Cond", "Arial Narrow", Arial,
        sans-serif;
    }
  }
}
.parallax {
  &.griffith {
    background-image: url("../img/age-of-collaboration/Wuxi_full_site_c09-min.jpg");
  }
  &.via-spire {
    /* The image used */
    background-image: url("../img/age-of-collaboration/fpz_20210627_0624-min.png");
    background-position: 50% 100%;
  }
  &.everton {
    /* The image used */
    background-image: url("../img/age-of-collaboration/MEIS_Everton_riverfront_view.jpg");
    background-position-x: 65%;
  }
  &.live {
    /* The image used */
    background-image: url("../img/age-of-collaboration/BLTa_210524_404-min.jpg");
    background-position-x: 0;
  }
  &.nyu {
    /* The image used */
    background-image: url("../img/age-of-collaboration/NYU_School_of_Global_Public_Health_Int_Lobby.png");
  }
}
